import React, { useEffect } from 'react';
import { Card, Col, Row } from 'antd';

import Layout from '../components/layout';
import SEO from "../components/seo"

const CustomBikes = () => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = 'https://www.autoscout24.ch/MVC/Content/as24-hci-desktop/js/e.min.js';
		script.async = true;

		document.body.appendChild(script);
	}, []);

	return (
		<Layout>
			<SEO
				title="Occasions"
				description="Retrouvez tous nos véhicules d'occasions disponibles dans notre garage."
				lang="fr"
				url="/vehicules-occasions"
			/>

			<div className="box-container first">
				<div className="content-block">
					<div className="content-block-bg" />
					<h2>Véhicules d'occasions</h2>

					<p>Black Way s'efforce de vous proposer constamment des occasions de qualité.</p>

					<div data-embedded-src="https://www.motoscout24.ch/fr/hci/list?design=3381&filter=7207" className="embedded-content-area"></div>

					<Row type="flex" align="center" style={{ marginTop: 80 }}>
						<Col xs={24} md={20} xxl={16}>
							<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
								<h3>DÉPÔT VENTE</h3>
								<p>Vous voulez vendre votre moto, mais vous n'avez pas le temps de vous en occuper ?</p>
								<p>Nous vous proposons notre service de dépot-vente. Nous prendrons alors en charge votre moto, et ce, jusqu'à la fin de sa vente.</p>
								<p>La moto sera alors visible dans notre Showroom et sur nos différents supports d'annonce comme facebook, anibis et motoscout24.</p>
								<p>Selon votre accord, nous donnerons la possibilité au potentiel acheteur de l'essayer, si celle-ci lui plaît nous nous occuperons alors de son immatriculation et son passage à la visite.</p>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</Layout>
	);
}

export default CustomBikes;
